html,
body,
div#root {
    height: 100vh;
}

div#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1;
}